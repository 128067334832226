@import "bootstrap/scss/bootstrap";

.logo-container {
  width: auto;
  height: 43px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.loader-wrap {
  z-index: 1;
}

.popup {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.popup-inner {
  position: relative;
  min-height: 100%;
  padding: 20px 0 100px 0;
}

.popup-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 20, 40, 0.3);
}

.popup-content {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  position: relative;
  top: 0px;
  max-width: 640px;
  margin: 0 auto;
  z-index: 2;
  border-radius: $border-radius;
  padding: 30px;
  background-color: $body-bg;

  &.large {
    max-width: 860px;
  }

  &.small {
    max-width: 500px;
  }

  &.extra-large {
    max-width: calc(1200px + 90px * 2);
  }

  .popup-header {
    height: 60px;
  }

  .popup-close {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0.3;
    position: absolute;
    font-size: 160%;
    top: 30px;
    right: 20px;
    height: 40px;
    width: 40px;

    &:hover {
      opacity: 1;
    }
  }
}

.popup-loader {
  position: absolute;
  z-index: 1021; /* overlapping .sticky-top */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(#ffffff, 0.8);
}

.pointer {
  cursor: pointer;
}

.popup-content {
  &.payment-methods-popup {
    max-width: 1000px;
  }
}

.img-payment-method {
  width: 100px;
}

.img-payment-qr {
  width: 185px;
}

.payment-method-card {
  &:hover {
    @extend .border-info
  }
}
